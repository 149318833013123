export const CSRF_TOKEN_NAME = 'CRAFT_CSRF_TOKEN';

export const BREAKPOINTS = {
  xs: 414,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1200,
  xl2: 1365,
};
